<template>
  <div class="container">
    <div class="head">
      <h1>销售大数据运维中心</h1>
      <div class="now_time">{{ nowTime }}</div>
      <a-radio-group class="date_range_container" v-model="timeRange" @change="timeRangeChange">
        <a-radio-button value="m">本月</a-radio-button>
        <a-radio-button value="q">本季度</a-radio-button>
        <a-radio-button value="y">本年</a-radio-button>
      </a-radio-group>
    </div>
    <div class="main_container">
      <div class="left_container">
        <div class="left_top_container">
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">今日完成工单数</div>
                <div class="value">{{ statistics.today_finish_order_count }}</div>
              </div>
              <div class="value_container">
                <div class="title" style="text-align: right">{{ todayFinishOrder.text }}</div>
                <div class="compare_value" :style="{color: todayFinishOrder.color}">{{ todayFinishOrder.rate }}<a-icon style="margin-left: 5px;" :type="todayFinishOrder.icon" /></div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">未完成工单总数</div>
                <div class="value">{{ statistics.unfinished_order_count }}</div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">服务客户总数</div>
                <div class="value">{{ statistics.serve_customer_count }}</div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">本月完成工单</div>
                <div class="value">{{ statistics.now_month_finish_order_count }}</div>
              </div>
              <div class="value_container">
                <div class="title" style="text-align: right">{{ nowMonthFinishOrder.text }}</div>
                <div class="compare_value" :style="{color: nowMonthFinishOrder.color}">{{ nowMonthFinishOrder.rate }}<a-icon style="margin-left: 5px;" :type="nowMonthFinishOrder.icon" /></div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">异常工单总数</div>
                <div class="value">{{ statistics.abnormal_order_count }}</div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
          <div class="container_bg left_top_item">
            <div class="item_container">
              <div class="value_container">
                <div class="title">待处理反馈总数</div>
                <div class="value">{{ statistics.pending_feedback_count }}</div>
              </div>
            </div>
            <div class="container_footer_bg"></div>
          </div>
        </div>
        <div class="left_bottom_container container_bg">
          <div style="height: 100%;width: 100%" id="map_container"></div>
          <div class="map_search">
            <div class="search_item">
              <div class="label">工单状态</div>
              <div class="search_container">
                <a-select :dropdownStyle="{backgroundColor: '#142957 !important'}" :defaultValue="orderStatus.ALL.v" size="small" style="width: 120px" @change="orderStatusChange">
                  <a-select-option v-for="item in orderStatus" :key="item.v" class="select_option" :value="item.v">{{ item.name }}</a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="container_footer_bg"></div>
        </div>
      </div>
      <div class="right_container container_bg">
        <div class="right_box">
          <div class="right_item_box" style="height: 25%">
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}工单趋势</div>
              <div id="trend_chart" v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container"></div>
            </div>
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}完成工单地区排行</div>
              <div id="area_chart" v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container"></div>
            </div>
          </div>
          <div class="right_item_box" style="height: 30%">
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}完成工单的工单类型</div>
              <div id="order_type_chart" v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container"></div>
            </div>
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}已评价工单的客户满意度</div>
              <div id="evaluate_chart" v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container"></div>
            </div>
          </div>
          <div class="right_item_box" style="height: 43%">
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}完成工单故障类型统计</div>
              <div class="rank_head">
                <span>排名</span>
                <div>故障类型</div>
                <div>数量</div>
              </div>
              <div v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container">
                <vue-seamless-scroll :data="faultData" style="height: 100%">
                  <div v-for="(item,index) in faultData" :key="item.part_id" class="rank_item">
                    <span :style="{backgroundColor: getRankTextColor(index)}">{{ index+1 }}</span>
                    <div>{{ item.big_fault_category.name }}</div>
                    <div>{{ item.count }}</div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
            <div class="right_item">
              <div class="right_item_title">{{ getTimeRangeText }}完成工单故障产品统计</div>
              <div class="rank_head">
                <span>排名</span>
                <div>产品名</div>
                <div>编号</div>
                <div>数量</div>
              </div>
              <div v-on:mouseover="canChangeTimeRange = false" v-on:mouseout="canChangeTimeRange = true" class="right_item_container">
                <vue-seamless-scroll :data="productData" style="height: 100%">
                  <div v-for="(item,index) in productData" :key="item.part_id" class="rank_item">
                    <span :style="{backgroundColor: getRankTextColor(index)}">{{ index + 1 }}</span>
                    <div>{{ item.single_product.product.name }}</div>
                    <div>{{ item.single_product.code }}</div>
                    <div>{{ item.count }}</div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="container_footer_bg"></div>
      </div>
    </div>
  </div>
</template>
<script>
import chinaJson from '@/assets/china';
import vueSeamlessScroll from 'vue-seamless-scroll'
import request from '@/utils/request';
export default {
  components: {
    vueSeamlessScroll
  },
  data () {
    return {
      nowTime: '',
      enterpriseId: 48,
      orderList: [],
      userList: [],
      mapChart: null,
      planePath: 'path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705',
      statistics: {},
      orderStatus: {
        ALL: {
          v: 0,
          name: '全部'
        },
        WAIT_RECEIVE: {
          v: 10,
          name: '待接受'
        },
        RECEIVED: {
          v: 20,
          name: '已接受'
        },
        DATED: {
          v: 30,
          name: '已预约'
        },
        SET_OUT: {
          v: 40,
          name: '已出发'
        },
        ARRIVE: {
          v: 50,
          name: '已到达'
        },
        HANDLE_OVER: {
          v: 60,
          name: '已完成'
        },
        CANCEL: {
          v: 80,
          name: '已取消'
        },
        REFUSE: {
          v: 90,
          name: '拒绝'
        },
        PAUSE: {
          v: 95,
          name: '暂缓'
        }
      },
      faultData: [],
      productData: [],
      evaluateChart: null,
      orderTypeChart: null,
      areaChart: null,
      trendChart: null,
      timeRange: 'm',
      canChangeTimeRange: true
    }
  },
  computed: {
    getTimeRangeText () {
      if (this.timeRange === 'm') {
        return '本月'
      } else if (this.timeRange === 'q') {
        return '本季度'
      } else if (this.timeRange === 'y') {
        return '本年'
      } else {
        return '-'
      }
    },
    todayFinishOrder () {
      if (this.statistics.today_finish_order_is_growth === 1){
        return { text: '较昨日增长', color: '#00ff65', icon: 'arrow-up', rate: this.statistics.today_finish_order_rate + '%' }
      } else if (this.statistics.today_finish_order_is_growth === -1){
        return { text: '较昨日下降', color: '#ff3946', icon: 'arrow-down', rate: this.statistics.today_finish_order_rate + '%' }
      } else {
        return { text: '-', color: '#fff', icon: '', rate: '-' }
      }
    },
    nowMonthFinishOrder () {
      if (this.statistics.now_month_finish_order_is_growth === 1){
        return { text: '较本月增长', color: '#00ff65', icon: 'arrow-up', rate: this.statistics.now_month_finish_order_rate + '%' }
      } else if (this.statistics.now_month_finish_order_is_growth === -1){
        return { text: '较本月下降', color: '#ff3946', icon: 'arrow-down', rate: this.statistics.now_month_finish_order_rate + '%' }
      } else {
        return { text: '-', color: '#fff', icon: '', rate: '-' }
      }
    }
  },
  created: function () {
    const that = this
    // 右上角时间
    this.setNowTime()
    setInterval(function () {
      that.setNowTime()
    },1000)
    // 获取左上角统计数据
    request({
      url: 'spectaculars/order_statistics',
      data: { 'enterprise_id': this.enterpriseId }
    }).then(r => {
      this.statistics = r.data.data
    })
    this.getFaultData()
    this.getProductData()
    setInterval(function () {
      if (that.canChangeTimeRange) {
        if (that.timeRange === 'm' ) {
          that.timeRange = 'q'
        } else if (that.timeRange === 'q' ) {
          that.timeRange = 'y'
        } else if (that.timeRange === 'y' ) {
          that.timeRange = 'm'
        }
        that.timeRangeChange()
      }
    }, 1000 * 10)
  },
  async mounted () {
    // 基于准备好的dom，初始化echarts实例
    this.mapChart = this.$echarts.init(document.getElementById('map_container'))
    this.$echarts.registerMap('china', chinaJson)
    this.mapChart.setOption({
      backgroundColor: '',
      tooltip: {
        trigger: 'item'
      },
      geo: {
        map: 'china',
        zoom: 1.2,
        label: {
          show:true,
          fontSize: "14",
          color: "#02a6b5"
        },
        roam: true,
        selectedModel: false,
        select: {
          label: {
            color: "#02a6b5"
          },
          itemStyle: {
            areaColor: '#142957',
          }
        },
        itemStyle: {
          areaColor: '#142957',
          borderColor: '#0692a4'
        },
        emphasis: {
          label: {
            color: "#02a6b5"
          },
          itemStyle: {
            areaColor: '#0037af',
          }
        }
      },
      series: []
    })
    await this.getUserList()
    await this.getOrderList()
    this.renderLine()
    this.evaluateChart = this.$echarts.init(document.getElementById('evaluate_chart'))
    this.evaluateChart.setOption({
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: '满意度',
          type: 'pie',
          radius: '80%',
          grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          },
          label: {
            color: 'rgba(255, 255, 255, 0.6)'
          },
          data: []
        }
      ]
    })
    this.getEvaluateData()
    this.orderTypeChart = this.$echarts.init(document.getElementById('order_type_chart'))
    this.orderTypeChart.setOption({
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: '工单类型',
          type: 'pie',
          radius: '80%',
          grid: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          },
          label: {
            color: 'rgba(255, 255, 255, 0.6)'
          },
          data: []
        }
      ]
    })
    this.getOrderTypeData()
    this.areaChart = this.$echarts.init(document.getElementById('area_chart'))
    this.areaChart.setOption({
      tooltip: {
        trigger: 'item'
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 10,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      series: [{
        data: [],
        type: 'bar',
        showBackground: true,
        barMaxWidth: 20,
        itemStyle: {
          color: this.$echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: '#83bff6'},
              {offset: 0.5, color: '#188df0'},
              {offset: 1, color: '#188df0'}
            ]
          )
        },
      }]
    })
    this.getAreaData()
    this.trendChart = this.$echarts.init(document.getElementById('trend_chart'))
    this.trendChart.setOption({
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 20,
        containLabel: true
      },
      legend: {
        data: ['新增工单', '完成工单'],
        textStyle: {
          color: 'rgba(255, 255, 255, 0.6)'
        },
        right: 0,
        top: 0
      },
      color: ['#108ee9', '#87d068'],
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      series: [{
        name: '新增工单',
        data: [],
        type: 'line',
        smooth: true
      },
      {
        name: '完成工单',
        data: [],
        type: 'line',
        smooth: true
      }]
    })
    this.getTrendData()
  },
  methods: {
    getTrendData () {
      request({
        url: 'spectaculars/trend_statistics',
        data: { 'enterprise_id': this.enterpriseId, time_range: this.timeRange }
      }).then(r => {
        const data = r.data.data
        const createData = []
        const finishData = []
        const xAxisData = []
        data.create_line.forEach(item => {
          createData.push(item.count)
          xAxisData.push(item.date)
        })
        data.finish_line.forEach(item => {
          finishData.push(item.count)
        })
        const options = this.trendChart.getOption()
        options.series[0].data = createData
        options.series[1].data = finishData
        options.xAxis[0].data = xAxisData
        this.trendChart.setOption(options)
      })
    },
    getAreaData () {
      request({
        url: 'spectaculars/region_statistics',
        data: { 'enterprise_id': this.enterpriseId, time_range: this.timeRange }
      }).then(r => {
        const data = r.data.data
        const chartData = []
        const xAxisData = []
        data.forEach(item => {
          chartData.push({
            name: item.region_name,
            value: item.count
          })
          xAxisData.push(item.region_name)
        })
        const options = this.areaChart.getOption()
        options.series[0].data = chartData
        options.xAxis[0].data = xAxisData
        this.areaChart.setOption(options)
      })
    },
    timeRangeChange () {
      this.getOrderTypeData()
      this.getEvaluateData()
      this.getProductData()
      this.getFaultData()
      this.getAreaData()
      this.getTrendData()
    },
    getRankTextColor (index) {
      const rank = index + 1;
      if (rank === 1) {
        return '#ed405d'
      } else if (rank === 2){
        return '#f78c44'
      } else if (rank === 3) {
        return '#49bcf7'
      } else {
        return '#878787'
      }
    },
    // 工单类型
    getOrderTypeData () {
      request({
        url: 'spectaculars/type_statistics',
        data: { 'enterprise_id': this.enterpriseId, time_range: this.timeRange }
      }).then(r => {
        const data = r.data.data
        const chartData = []
        data.forEach(item => {
          chartData.push({
            name: item.type_name,
            value: item.count
          })
        })
        const options = this.orderTypeChart.getOption()
        options.series[0].data = chartData
        this.orderTypeChart.setOption(options)
      })
    },
    // 满意度数据
    getEvaluateData () {
      request({
        url: 'spectaculars/score_statistics',
        data: { 'enterprise_id': this.enterpriseId, time_range: this.timeRange }
      }).then(r => {
        const data = r.data.data
        const chartData = []
        data.forEach(item => {
          chartData.push({
            name: item.score_name,
            value: item.count
          })
        })
        const options = this.evaluateChart.getOption()
        options.series[0].data = chartData
        this.evaluateChart.setOption(options)
      })
    },
    // 获取故障类型
    getFaultData () {
      request({
        url: 'spectaculars/fault_category_ranking',
        data: { 'enterprise_id': this.enterpriseId }
      }).then(r => {
        this.faultData = r.data.data
      })
    },
    getProductData () {
      request({
        url: 'spectaculars/single_product_fault_ranking',
        data: { 'enterprise_id': this.enterpriseId, time_range: this.timeRange }
      }).then(r => {
        this.productData = r.data.data
      })
    },
    async orderStatusChange (value) {
      const options = this.mapChart.getOption()
      options.series = [{
        type: 'lines',
        zlevel: 1,
        effect: {
          show: true,
          period: 6,
          trailLength: 0.7,
          color: '#fff',
          symbolSize: 3
        },
        lineStyle: {
          width: 0,
          curveness: 0.2
        },
        data: []
      },{
        type: 'lines',
        zlevel: 2,
        effect: {
          show: true,
          period: 6,
          trailLength: 0,
          symbol: this.planePath,
          symbolSize: 15
        },
        lineStyle: {
          width: 1,
          opacity: 0.4,
          curveness: 0.2
        },
        data: []
      }]
      this.mapChart.setOption(options, true)
      await this.getUserList()
      await this.getOrderList(value)
      this.renderLine()
    },
    renderLine () {
      const options = this.mapChart.getOption()
      const userOrderArr = []
      this.userList.forEach(user => {
        this.orderList.forEach(order => {
          if(user.location && order.location && user.id === order.handler_id){
            userOrderArr.push([
              {coord: user.location.split(',')},
              {coord: order.location.split(',')}
            ])
          }
        })
      })
      options.series.push({
        type: 'lines',
        zlevel: 1,
        tooltip: {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          textStyle: {
            color: 'rgba(0,0,0,0)'
          },
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0);'
        },
        effect: {
          show: true,
          period: 6,
          trailLength: 0.7,
          color: '#fff',
          symbolSize: 3
        },
        lineStyle: {
          width: 0,
          curveness: 0.2
        },
        data: userOrderArr
      })
      options.series.push({
        type: 'lines',
        zlevel: 2,
        tooltip: {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
          textStyle: {
            color: 'rgba(0,0,0,0)'
          },
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0);'
        },
        effect: {
          show: true,
          period: 6,
          trailLength: 0,
          symbol: this.planePath,
          symbolSize: 15
        },
        lineStyle: {
          color: 'rgba(0, 255, 101, .4)',
          width: 1,
          opacity: 0.4,
          curveness: 0.2
        },
        data: userOrderArr
      })
      this.mapChart.setOption(options)
    },
    async getUserList () {
      await request({
        url: 'spectaculars/map_user_list',
        data: { 'enterprise_id': this.enterpriseId }
      }).then(r => {
        this.userList = r.data.data
        let userSpot = []
        this.userList.forEach(item => {
          userSpot.push({
            user: item,
            value: item.location.split(','),
            label: {
              show: false
            },
            itemStyle: {
              color: 'red'
            }
          })
        })
        const options = this.mapChart.getOption()
        options.series.push({
          zlevel: 3,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          // animation: true,
          rippleEffect: {
            brushType: 'stroke'
          },
          symbolSize: function () {
            return 10
          },
          tooltip: {
            formatter: function (params) {
              const user = params.data.user
              return `姓名：${user.real_name}<br/>联系电话：${user.mobile}<br/>定位位置：${user.address}<br/>定位时间：${user.last_location_time}`
            }
          },
          data: userSpot
        })
        this.mapChart.setOption(options)
      })
    },
    async getOrderList (status = null) {
      let p = { 'enterprise_id': this.enterpriseId }
      if (status) {
        p.status = status
      }
      await request({
        url: 'spectaculars/map_work_order_list',
        data: p
      }).then(r => {
        this.orderList = r.data.data
        let orderSpot = []
        this.orderList.forEach(item => {
          orderSpot.push({
            value: item.location.split(','),
            label: {
              show: false
            },
            order: item,
            itemStyle: {
              color: '#aad755'
            }
          })
        })
        const options = this.mapChart.getOption()
        options.series.push({
          zlevel: 3,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          // animation: true,
          rippleEffect: {
            brushType: 'stroke'
          },
          symbolSize: function () {
            return 10
          },
          tooltip: {
            formatter: function (params) {
              const order = params.data.order
              return `联系人：${order.linkman_name}<br/>联系电话：${order.linkman_mobile}<br/>地址：${order.full_address}`
            }
          },
          data: orderSpot
        })
        this.mapChart.setOption(options)
      })
    },
    setNowTime () {
      var date = new Date()
      const year = date.getFullYear()
      const month = this.autoZero(date.getMonth() + 1)
      const day = this.autoZero(date.getDate())
      const hour = this.autoZero(date.getHours())
      const minute = this.autoZero(date.getMinutes())
      const second = this.autoZero(date.getSeconds())
      const nowTime = year + '年' + month + '月' + day + '日' + hour + '时' + minute + '分' + second + '秒'
      this.nowTime = nowTime
    },
    autoZero (number) {
      if (number >= 10) {
        return number
      }
      return '0' + number
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .amap-logo, /deep/ .amap-copyright, /deep/ .amap-controls{
  display: none !important;
}
/deep/ .ant-radio-button-wrapper-checked{
  color: #40a9ff !important;
}
.ant-radio-button-wrapper{
  border: none;
  background: none;
  color: #fff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  box-shadow: -1px 0 0 0 #d9d9d9;
  background: none;
}
.ant-radio-button-wrapper:focus-within{
  outline: none;
}
.container{
  height: 100vh;
  background: url('../assets/image/board_bg.jpg');
  background-size: 100%;
  background-repeat:no-repeat;
  .head{
    height: 105px;
    background: url('../assets/image/board_head_bg.png') no-repeat center center;
    position: relative;
    h1{
      color: #fff;
      text-align: center;
      font-size: 42px;
      line-height: 90px;
      margin-bottom: 0;
    }
    .now_time{
      position: absolute;
      left: 30px;
      top: 0;
      line-height: 70px;
      color: #fff;
      font-size: 24px;
    }
    .date_range_container{
      position: absolute;
      right: 30px;
      top: 18px;
    }
  }
  .main_container{
    display: flex;
    height: calc(100vh - 105px);
    padding: 0 20px;
    padding-bottom: 15px;
    .left_top_container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        height: 25%;
        .left_top_item{
          width: 32.7%;
          height: 48%;
          padding: 1.5% 1.5%;
          .item_container{
            display: flex;
            justify-content: space-between;
            height: 100%;
          }
          .value_container{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .title{
            color: #02a6b5;
            font-size: 1rem;
            line-height: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .value{
            white-space: nowrap;
            font-size: 2.2rem;
            line-height: 2.2rem;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 4.5%;
          }
          .compare_value{
            white-space: nowrap;
            font-size: 1.5rem;
            line-height: 1.5rem;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 4.5%;
          }
        }
        .has_compare{
          flex-direction: row;
          justify-content: space-between;
        }
      }
    .left_container{
      display: flex;
      flex-direction: column;
      width: 55%;
      .left_bottom_container{
        position: relative;
        flex: 1;
        margin-top: 1%;
        .map_search{
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 999;
          .search_item{
            display: flex;
            align-items: center;
            color: #02a6b5;
            .label{
              margin-right: 10px;
            }
            /deep/ .ant-select-selection{
              background-color: #142957;
              border-color: #02a6b5;
              color: #02a6b5;
              i {
                color: #02a6b5
              }
            }
          }
        }
      }
    }
    .right_container{
      flex: 1;
      margin-left: 0.7%;
      .right_box{
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
      .right_item_box{
        display: flex;
        justify-content: space-between;
        height: 32%;
      }
      .rank_head{
        display: flex;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        color: rgba(255,255,255,.6);
        font-size: 0.9rem;
        text-align: center;
        span{
          width: 30px;
          overflow: hidden;
        }
        div{
          flex: 1;
          overflow: hidden;
        }
      }
      .rank_item{
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        margin-bottom: 8px;
        div{
          flex: 1;
          overflow: hidden;
          text-align: center;
          color: rgba(255,255,255,.6);
          font-size: 0.9rem;
        }
        span{
          width: 30px;
          height: 30px;
          line-height: 30px;
          border-radius: 5px;
          overflow: hidden;
          text-align: center;
          color: #fff;
          font-weight: bold;
          background: #878787;
          font-size: 1rem;
        }
      }
      .right_item{
        width: 49%;
        background: rgba(0, 37, 112, .3);
        display: flex;
        flex-direction: column;
        .right_item_title{
          color: #02a6b5;
          padding: 5px 10px;
          background-image: linear-gradient(to right, #011c53 , rgba(0, 37, 112, .3));
        }
        .right_item_container{
          flex: 1;
          overflow: hidden;
          padding: 0 10px;
        }
      }
    }
  }

  .container_bg{
    position: relative;
    border: 1px solid rgba(25,186,139,.17);
    background: rgba(255,255,255,.04);
  }
  .container_bg::before{
    border-left: 2px solid #02a6b5;
    left: 0;
    position: absolute;
    width: .8rem;
    height: .8rem;
    content: "";
    border-top: 2px solid #02a6b5;
    top: 0;
    z-index: 999;
  }
  .container_bg::after{
    border-right: 2px solid #02a6b5;
    right: 0;
    position: absolute;
    width: .8rem;
    height: .8rem;
    content: "";
    border-top: 2px solid #02a6b5;
    top: 0;
    z-index: 999;
  }
  .container_footer_bg:before {
    position: absolute;
    width: .8rem;
    height: .8rem;
    content: "";
    border-bottom: 2px solid #02a6b5;
    bottom: 0;
    border-left: 2px solid #02a6b5;
    left: 0;
    z-index: 999;
  }
  .container_footer_bg:after {
    position: absolute;
    width: .8rem;
    height: .8rem;
    content: "";
    border-bottom: 2px solid #02a6b5;
    bottom: 0;
    border-right: 2px solid #02a6b5;
    right: 0;
    z-index: 999;
  }
}

</style>
